import PropTypes from "prop-types";
import React from "react";

import { FiFacebook, FiTwitter, FiInstagram } from "react-icons/fi";
import EmailSignupForm from "../common/email-signup-form";
import { GatsbyImage } from "gatsby-plugin-image";

const ContactBlock = ({ images, data, contact }) => {
  const image = images.nodes.find((imageObj) => imageObj.name === data.image);
  return (
    <section id="" className="relative text-white bg-purple">
      <div className="relative w-full mx-auto text-center py-28 md:w-1/2 container-wide">
        <div className="flex flex-col items-center justify-center text-orange">
          <h4
            className="italic c-h4 w-full text-left"
            data-sal="fade"
            data-sal-duration="600"
            data-sal-delay=""
            data-sal-easing="ease"
          >
            {data.heading}
          </h4>
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            alt={image.name}
            loading="eager"
            objectFit="contain"
            className="w-full h-auto mx-auto"
          />
          <p
            className="mt-6 mb-4 c-sub1 "
            data-sal="fade"
            data-sal-duration="600"
            data-sal-delay="200"
            data-sal-easing="ease"
          >
            {contact.address}
          </p>
          <a
            href={`mailto:${contact.email}`}
            className="font-bold c-sub1 hover:opacity-50"
            data-sal="fade"
            data-sal-duration="600"
            data-sal-delay="400"
            data-sal-easing="ease"
          >
            {contact.email}
          </a>
          <ul
            className="flex flex-row justify-center w-full mt-4 list-none "
            data-sal="fade"
            data-sal-duration="600"
            data-sal-delay="600"
            data-sal-easing="ease"
          >
            {/* <li className="nav-item">
              <a
                className="flex items-center px-3 py-2 hover:opacity-75"
                href="https://www.facebook.com/SparkRise"
                aria-label="SparkRise® on Facebook"
                target="_blank"
                rel="noreferrer"
              >
                <FiFacebook size="20px" />
              </a>
            </li> */}
            {/* <li className="nav-item">
              <a
                className="flex items-center px-3 py-2 hover:opacity-75"
                href="#instagram"
                aria-label="SparkRise® on Instagram"
              >
                <FiInstagram size="20px" />
              </a>
            </li> */}
            {/* <li className="nav-item">
              <a
                className="flex items-center px-3 py-2 hover:opacity-75"
                href="https://twitter.com/WeAreSparkRise"
                aria-label="SparkRise® on Twitter"
                target="_blank"
                rel="noreferrer"
              >
                <FiTwitter size="20px" />
              </a>
            </li> */}
          </ul>
        </div>
        <div
          data-sal="slide-up"
          data-sal-duration="600"
          data-sal-delay="600"
          data-sal-easing="ease"
        >
          <h4 className="mt-8 mb-2 c-h4 w-full">{data.formText}</h4><br />
          <EmailSignupForm />
          <h6 className="mt-8 mb-2 c-h6 w-full">Sign up to be notified when your new digital playground for doing good,<i> and being entertained while you're doing it,</i> goes live!</h6>
        </div>
      </div>
    </section>
  );
};

ContactBlock.propTypes = {
  image: PropTypes.object,
  data: PropTypes.object,
  contact: PropTypes.object,
};

export default ContactBlock;
