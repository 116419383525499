import React, { useState } from "react";
import cx from "classnames";
import addToMailchimp from "gatsby-plugin-mailchimp";

const EmailSignupForm = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    addToMailchimp(email, null)
      .then((data) => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        console.log("res:", data);
        setMessageType(true);
        setMessage("Thank you for Signing up!");
      })
      .catch(() => {
        setMessageType(false);
        setMessage(
          "Unable to submit your email, please refresh the page and try again..."
        );
      });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div
        className={cx(
          "p-4 opacity-100 transition-all duration-500 rounded-md",
          {
            "hidden p-0 opacity-0 transition-all duration-500": message === "",
            "bg-light-purple text-white": messageType,
            "bg-rust text-white": !messageType,
          }
        )}
      >
        {message}
      </div>
      <div
        className={cx("block", {
          hidden: message.length > 0,
        })}
      >
        <input
          className="mb-2 text-black rounded"
          type={"text"}
          value={email}
          placeholder="Email Address"
          name="email"
          onChange={handleEmailChange}
        />
        <input type={"submit"} value="ACTIVATE" className="ml-2 c-button" />
      </div>
    </form>
  );
};

export default EmailSignupForm;
