import React, { useContext } from "react";
import SEO from "../components/seo";

import { StateContext } from "../state/StateProvider";
import InView from "react-intersection-observer";

// Sections
import Layout from "../components/layout";
import Hero from "../components/home/hero";
import VideoBlock from "../components/home/video-block";
import QuoteBlock from "../components/home/quote-block";
import CtaBlock from "../components/home/cta-block";
import ContactBlock from "../components/home/contact-block";

// DATA
import { useStaticQuery, graphql } from "gatsby";

export default function IndexPage({ data }) {
  const { homePage, contact, campaigns, campaignImages, homeImages, contactImages } = data;
  const { isDark, setTheme } = useContext(StateContext);

  const toggleNavStyle = (inView) => {
    if (inView === true) {
      setTheme(true);
    } else {
      setTheme(false);
    }
  };

  return (
    <Layout>
      <SEO keywords={[`sparkrise`, `change`]} title="SparkRise® | Home" />

      <ContactBlock
        images={contactImages}
        data={homePage.frontmatter.contactBlock}
        contact={contact.frontmatter}
      />

    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    homePage: markdownRemark(frontmatter: { title: { eq: "home" } }) {
      frontmatter {
        title
        videoBlock {
          caption
          videoId
          button {
            text
            url
          }
        }
        quoteSlides {
          attribution
          image
          objectPosition
          quote
        }
        newsSlides {
          image
          objectPosition
          quote
          button {
            text
            url
          }
        }
        cta {
          body
          heading
          image
          button {
            text
            url
          }
        }
        contactBlock {
          formText
          heading
          image
        }
      }
    }
    contact: markdownRemark(frontmatter: { title: { eq: "globals" } }) {
      frontmatter {
        title
        email
        address
      }
    }
    campaigns: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "campaign-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            slug
            title
            hero {
              image
              objectPosition
            }
            thumbnail {
              alertMessage
              heading
              subheading
              button {
                text
                url
              }
            }
          }
        }
      }
    }
    homeImages: allFile(filter: { relativeDirectory: { eq: "home-images" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 99)
        }
      }
    }
    contactImages: allFile(filter: { relativeDirectory: { eq: "home-images" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 99)
        }
      }
    }
    campaignImages: allFile(
      filter: { relativeDirectory: { eq: "campaign-images" } }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 90)
        }
      }
    }
  }
`;
